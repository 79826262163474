<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-row :gutter="24">
        <!-- 部门树 -->
        <a-col :span="4">
          <dept-tree ref="deptTree" :deptOptions="deptOptions" @select="clickDeptNode" />
        </a-col>
        <a-col :span="20">
          <!-- 条件搜索 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="48">
                <a-col :md="6" :sm="24">
                  <a-form-item label="设备名称">
                    <a-input v-model="queryParam.devName" placeholder="请输入" allow-clear/>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item label="水表编号">
                    <a-input v-model="queryParam.devSn" placeholder="请输入" allow-clear/>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item label="水表类型">
                    <a-select v-model="queryParam.typeName" placeholder="请选择" allow-clear>
                      <a-select-option v-for="(item,key) in typeList" :key="key" :value="item.name">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <template v-if="advanced">
                  <a-col :md="6" :sm="24">
                    <a-form-item label="设备编号">
                      <a-input v-model="queryParam.devCode" placeholder="请输入" allow-clear/>
                    </a-form-item>
                  </a-col>
                  <a-col :md="6" :sm="24">
                    <a-form-item label="设备状态">
                      <a-select v-model="queryParam.devStatus" show-search :filter-option="filterOption" placeholder="请选择">
                        <a-select-option v-for="(item,key) in [{name:'正常',id:0},{name:'报警',id:1}]" :key="key" :value="item.id">{{ item.name }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="6" :sm="24">
                    <a-form-item label="是否欠费">
                      <a-select v-model="queryParam.sfArrearage" show-search :filter-option="filterOption" placeholder="请选择">
                        <a-select-option v-for="(item,key) in [{name:'否',id:0},{name:'是',id:1}]" :key="key" :value="item.id">{{ item.name }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="6" :sm="24">
                    <a-form-item label="设备开关阀">
                      <a-select v-model="queryParam.status" show-search :filter-option="filterOption" placeholder="请选择">
                        <a-select-option v-for="(item,key) in [{name:'关',id:0},{name:'开',id:1}]" :key="key" :value="item.id">{{ item.name }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="6" :sm="24">
                    <a-form-item label="设备配价名称">
                      <a-input v-model="queryParam.devOrdinateName" placeholder="请输入" allow-clear/>
                    </a-form-item>
                  </a-col>
                  <a-col :md="6" :sm="24">
                    <a-form-item label="用户类型">
                      <a-input v-model="queryParam.devUserType" placeholder="请输入" allow-clear/>
                    </a-form-item>
                  </a-col>
                  <a-col :md="6" :sm="24">
                    <a-form-item label="绑定人名称">
                      <a-input v-model="queryParam.userName" placeholder="请输入" allow-clear/>
                    </a-form-item>
                  </a-col>
                  <a-col :md="6" :sm="24">
                    <a-form-item label="绑定人手机号">
                      <a-input v-model="queryParam.userPhone" placeholder="请输入" allow-clear/>
                    </a-form-item>
                  </a-col>
                </template>
                <a-col :md="6" :sm="24">
                  <span class="table-page-search-submitButtons" :style="advanced && { overflow: 'hidden' } || {} ">
                    <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                    <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                    <a @click="toggleAdvanced" style="margin-left: 8px">
                      {{ advanced ? '收起' : '展开' }}
                      <a-icon :type="advanced ? 'up' : 'down'" />
                    </a>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 上传文件 -->
          <import-excel ref="importExcel" @ok="getList" />
          <!-- 操作 -->
          <div class="table-operations">
            <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['wisdom:device:add']">
              <a-icon type="plus" />新增
            </a-button>
            <!-- <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['wisdom:device:edit']">
          <a-icon type="edit" />修改
        </a-button> -->
            <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['wisdom:device:remove']">
              <a-icon type="delete" />删除
            </a-button>
            <a-button type="primary" @click="$refs.importExcel.importExcelHandleOpen()" v-hasPermi="['wisdom:device:dr']">
              <a-icon type="import" />导入
            </a-button>
            <a-button type="primary" @click="handleExport" v-hasPermi="['wisdom:device:export']">
              <a-icon type="download" />导出
            </a-button>
            <a-button type="dashed" :disabled="multiple" @click="$refs.createForm2.handleUpdate(1, ids)" v-hasPermi="['wisdom:device:jsday']">设置结算日</a-button>
            <!-- <a-button type="dashed" :disabled="multiple" @click="$refs.createForm2.handleUpdate(2, ids)" v-hasPermi="['wisdom:device:warn']">设置用量警告</a-button> -->
            <a-button type="dashed" :disabled="multiple" @click="$refs.createForm2.handleUpdate(3, ids)" v-hasPermi="['wisdom:device:zq']">设置上报周期</a-button>
            <a-button type="dashed" :disabled="multiple" @click="$refs.createForm2.handleUpdate(4, ids)" v-hasPermi="['wisdom:device:ordinate:sz']">设置配价方案</a-button>
            <a-button type="dashed" :disabled="multiple" @click="visible = true" v-hasPermi="['wisdom:device:yf']">设置强制开阀</a-button>
            <a-button type="dashed" :disabled="multiple" @click="$refs.createForm2.handleUpdate(7, ids)" v-hasPermi="['wisdom:device:ordinate:ssgs']">设置所属公司</a-button>
            <a-button type="dashed" :disabled="single" @click="$refs.createForm2.handleUpdate(5, ids)" v-hasPermi="['wisdom:device:bds']">设置表底数</a-button>
            <!-- <a-button type="dashed" :disabled="single" @click="$refs.createForm2.handleUpdate(6, ids)" v-hasPermi="['wisdom:device:ghbh']">更换表号</a-button> -->
            <a-button type="dashed" :disabled="single" @click="$refs.createForm2.handleUpdate(6, ids)" v-hasPermi="['wisdom:device:hbhao']">一键换表</a-button>
            <a-button type="dashed" :disabled="multiple" @click="handleValve(ids,1)" v-hasPermi="['wisdom:device:valve']">开阀</a-button>
            <a-button type="dashed" :disabled="multiple" @click="handleValve(ids,0)" v-hasPermi="['wisdom:device:valve']">关阀</a-button>
            <table-setting
              :style="{float: 'right'}"
              :table-size.sync="tableSize"
              v-model="columns"
              :refresh-loading="loading"
              @refresh="getList" />
          </div>
          <!-- 增加修改 -->
          <create-form
            ref="createForm"
            @ok="getList"
          />
          <!-- 批量操作 -->
          <create-form2
            ref="createForm2"
            @ok="getList"
          />
          <!-- 详情 -->
          <info
            ref="info"
            @ok="getList"
          />
          <!-- 缴费 -->
          <recharge-form
            ref="rechargeForm"
            :typeOptions="dict.type['sys_yes_no']"
            @ok="getList"
          />
          <!-- 数据展示 -->
          <a-table
            :components="drag(columns)"
            :loading="loading"
            :size="tableSize"
            rowKey="id"
            :columns="columns"
            :data-source="list"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :pagination="false"
            :scroll="{ x: 2000 }"
            @change="onChange"
            :bordered="tableBordered">
            <span slot="address" slot-scope="text, record">
              <span>{{ record.provinceCode }} {{ record.cityCode }} {{ record.areaCode }} {{ record.communityName }} {{ record.buildingName }}</span>
            </span>
            <span slot="devStatus" slot-scope="text, record">
              <a-tag color="green" v-if="record.devStatus == 0">正常</a-tag>
              <a-tag color="red" v-else-if="record.devStatus == 1">报警</a-tag>
            </span>
            <span slot="sfDx" slot-scope="text, record">
              <a-tag color="orange" v-if="record.sfDx == 0">否</a-tag>
              <a-tag color="blue" v-else-if="record.sfDx == 1">是</a-tag>
            </span>
            <span slot="onLine" slot-scope="text, record">
              <a-tag color="orange" v-if="record.onLine == 2">异常</a-tag>
              <a-tag color="green" v-if="record.onLine == 1">在线</a-tag>
              <a-tag color="red" v-else-if="record.onLine == 0">离线</a-tag>
            </span>
            <span slot="sfArrearage" slot-scope="text, record">
              <a-tag color="blue" v-if="record.sfArrearage == 0">否</a-tag>
              <a-tag color="orange" v-else-if="record.sfArrearage == 1">是</a-tag>
            </span>
            <span slot="sfQfValve" slot-scope="text, record">
              <a-tag color="blue" v-if="record.sfQfValve == 0">否</a-tag>
              <a-tag color="orange" v-else-if="record.sfQfValve == 1">是</a-tag>
            </span>
            <span slot="status" slot-scope="text, record">
              <a-tag color="orange" v-if="record.status == 0">关</a-tag>
              <a-tag color="blue" v-else-if="record.status == 1">开</a-tag>
              <a-tag color="red" v-else-if="record.status == 2">故障</a-tag>
              <a-tag color="cyan" v-else-if="record.status == 3">无阀</a-tag>
            </span>
            <span slot="userInfo" slot-scope="text, record">
              <div class="spanBox">
                <span>{{ record.devUserType }} {{ record.userName }} {{ record.userPhone }} {{ record.userIccid }}</span>
                <!-- <span>{{ record.devUserType }}</span>
                <span>{{ record.userName }}</span>
                <span>{{ record.userPhone }}</span>
                <span>{{ record.userIccid }}</span> -->
              </div>
            </span>
            <span slot="coordinationValence" slot-scope="text, record">
              <div class="spanBox">
                <span>{{ record.name }}</span>
                <span>
                  <a-tag color="blue" v-if="record.payType == 0">平台预付费</a-tag>
                  <a-tag color="blue" v-else-if="record.payType == 1">表端预付费</a-tag>
                  <a-tag color="blue" v-if="record.sfQfValve == 0">欠费不关阀</a-tag>
                  <a-tag color="orange" v-else-if="record.sfQfValve == 1">欠费关阀</a-tag>
                </span>
              </div>
            </span>
            <span slot="endTime" slot-scope="text, record">
              {{ parseTime(record.endTime) }}
            </span>
            <span slot="createTime" slot-scope="text, record">
              {{ parseTime(record.createTime) }}
            </span>
            <span slot="operation" slot-scope="text, record">
              <a @click="$refs.info.info(record.id)" v-hasPermi="['wisdom:device:query']"><a-icon type="eye"/>详情</a>
              <a-divider type="vertical" v-hasPermi="['wisdom:devicesettleday:page']"/>
              <a @click="goRouter('/statistics/list',{devCode:record.devCode})" v-hasPermi="['wisdom:devicesettleday:page']"><a-icon type="eye"/>抄收统计</a>
              <a-divider type="vertical" v-hasPermi="['wisdom:device:edit']"/>
              <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['wisdom:device:edit']"><a-icon type="edit" />修改</a>
              <a-divider type="vertical" v-hasPermi="['wisdom:device:remove']"/>
              <a @click="$refs.rechargeForm.info(record.id,1)" v-hasPermi="['wisdom:orderrefund:jf']">缴费</a>
              <a-divider type="vertical" v-hasPermi="['wisdom:orderrefund:jf']"/>
              <a @click="handleDelete(record)" v-hasPermi="['wisdom:device:remove']"><a-icon type="delete" />删除</a>
            </span>
          </a-table>
          <!-- 分页 -->
          <a-pagination
            class="ant-table-pagination"
            :pageSizeOptions="['10','50','100','200']"
            show-size-changer
            show-quick-jumper
            :current="queryParam.pageNum"
            :total="total"
            :page-size="queryParam.pageSize"
            :showTotal="total => `共 ${total} 条`"
            @showSizeChange="onShowSizeChange"
            @change="changeSize"
          />
        </a-col>
      </a-row>
    </a-card>
    <a-modal v-model="visible" title="提示" :footer="null">
      <div style="padding-bottom: 80px;">
        <p>请选择并设置方式</p>
      </div>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="handleYf(1)">
            强制开阀
          </a-button>
          <a-button type="dashed" :loading="submitLoading" @click="handleYf(0)">
            恢复默认
          </a-button>
        </a-space>
      </div>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import RechargeForm from '@/views/pages/payment-refund/modules/recharge-form'
import { areaList } from '@/api/pages/region'
import { devicePage, deviceDel, deviceEditValve, deviceTypeList, deviceEditYf } from '@/api/pages/device'
import CreateForm from './modules/CreateForm'
import CreateForm2 from '../devmodules/CreateForm2.vue'
import Info from '../devmodules/info'
import { tableMixin } from '@/store/table-mixin'
import ImportExcel from '../devmodules/ImportExcel'
import DeptTree from '@/components/DeptTree/DeptTree'
import tableDragResize from '@/utils/tableDragResize'

export default {
  mixins: [tableMixin, tableDragResize],
  name: 'Config',
  components: {
    CreateForm, ImportExcel, Info, CreateForm2, DeptTree, RechargeForm
  },
  dicts: ['sys_yes_no'],
  data () {
    return {
      submitLoading: false,
      visible: false,
      // 部门树选项
      deptOptions: [{
        id: 0,
        label: '',
        children: []
      }],
      typeList: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      refreshing: false,
      total: 0,
      // 日期范围
      dateRange: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        typeName: 'LoRa水表',
        companyName: null,
        trade: null,
        stockCode: null,
        listingLocation: null
      },
      columns: [
        {
          title: '设备名称',
          width: 160,
          dataIndex: 'devName',
          fixed: 'left',
          align: 'center',
          ellipsis: true
        },
        {
          title: '水表编号',
          width: 160,
          dataIndex: 'devSn',
          fixed: 'left',
          align: 'center',
          ellipsis: true,
          sorter: true
        },
        {
          title: '设备类型',
          width: 120,
          dataIndex: 'typeName',
          align: 'center',
          ellipsis: true
        },
        // {
        //   title: 'iccid',
        //   dataIndex: 'iccid',
        //   align: 'center'
        // },
        {
          title: '设备编号',
          width: 160,
          dataIndex: 'devCode',
          align: 'center',
          ellipsis: true,
          sorter: true
        },
        {
          title: '当前版本',
          dataIndex: 'devVersion',
          width: 160,
          align: 'center',
          ellipsis: true
        },
        {
          title: '所属公司',
          width: 120,
          dataIndex: 'companyName',
          align: 'center',
          ellipsis: true
        },
        {
          title: '余额',
          width: 100,
          dataIndex: 'balance',
          align: 'center',
          ellipsis: true,
          sorter: true
        },
        {
          title: '表端剩余水量',
          width: 110,
          dataIndex: 'residueWater',
          align: 'center',
          ellipsis: true
        },
        {
          title: '地址',
          width: 200,
          dataIndex: 'address',
          scopedSlots: { customRender: 'address' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '添加电信平台',
          width: 100,
          dataIndex: 'sfDx',
          scopedSlots: { customRender: 'sfDx' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '设备状态',
          width: 100,
          dataIndex: 'devStatus',
          scopedSlots: { customRender: 'devStatus' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '绑定人信息',
          width: 200,
          dataIndex: 'userInfo',
          scopedSlots: { customRender: 'userInfo' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '配价方案',
          width: 200,
          dataIndex: 'coordinationValence',
          scopedSlots: { customRender: 'coordinationValence' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '用量告警',
          width: 100,
          dataIndex: 'dosageWarn',
          align: 'center',
          ellipsis: true
        },
        {
          title: '用水总量',
          width: 100,
          dataIndex: 'useWaterAll',
          align: 'center',
          ellipsis: true
        },
        {
          title: '反向用水总量',
          width: 100,
          dataIndex: 'fxUseWaterAll',
          align: 'center',
          ellipsis: true
        },
        {
          title: '最后一次更新时间',
          width: 180,
          dataIndex: 'endTime',
          scopedSlots: { customRender: 'endTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '离线时间',
        //   dataIndex: 'offTime',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '是否在线',
        //   dataIndex: 'onLine',
        //   scopedSlots: { customRender: 'onLine' },
        //   align: 'center'
        // },
        // {
        //   title: '支付方式',
        //   width: 100,
        //   dataIndex: 'payType',
        //   scopedSlots: { customRender: 'payType' },
        //   align: 'center',
        //   ellipsis: true
        // },
        {
          title: '上报周期',
          dataIndex: 'reportingCycle',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '结算日',
          dataIndex: 'settleDay',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否有欠费',
          width: 100,
          dataIndex: 'sfArrearage',
          scopedSlots: { customRender: 'sfArrearage' },
          align: 'center',
          ellipsis: true
        },
        // {
        //   title: '欠费是否关阀',
        //   dataIndex: 'sfQfValve',
        //   scopedSlots: { customRender: 'sfQfValve' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '设备开关阀',
          width: 100,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '添加时间',
          width: 180,
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          align: 'center',
          ellipsis: true,
          sorter: true
        },
        {
          title: '操作',
          width: 350,
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          fixed: 'right',
          align: 'center',
          ellipsis: true
        }
      ]
    }
  },
  filters: {
  },
  created () {
    deviceTypeList().then(response => {
      const list = response.data
      list.map(item => {
        this.typeList.push(item)
      })
    })
    this.getDeptTree()
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onChange(pagination, filters, sorter) {
      console.log('params', pagination, filters, sorter)
      if (sorter.order) {
        this.queryParam.sortField = sorter.columnKey
        this.queryParam.sortOrder = sorter.order.toUpperCase()
      } else {
        this.queryParam.sortField = undefined
        this.queryParam.sortOrder = undefined
      }
      this.getList()
    },
    /** 查询区域下拉树结构 */
    getDeptTree() {
      areaList().then(response => {
        this.deptOptions = response.data
      })
    },
    handleYf (type) {
      var that = this
      this.submitLoading = true
      const list = []
      that.ids.map(item => {
        list.push({
          id: item,
          type: type
        })
      })
      return deviceEditYf(list)
        .then(() => {
          that.onSelectChange([], [])
          that.getList()
          that.$message.success(
            '操作成功',
            3
          )
        }).finally(() => {
          this.visible = false
          this.submitLoading = false
        })
    },
    clickDeptNode(id, e) {
      console.log(id, e.selectedNodes[0].data.props)
      const label = e.selectedNodes[0].data.props.label
      const level = e.selectedNodes[0].data.props.level
      if (level < 4) {
        this.queryParam.communityId = null
        this.queryParam.buildingId = null
        this.queryParam.pcaName = label
      } else if (level === 4) {
        this.queryParam.pcaName = null
        this.queryParam.buildingId = null
        this.queryParam.communityId = id
      } else if (level === 5) {
        this.queryParam.pcaName = null
        this.queryParam.communityId = null
        this.queryParam.buildingId = id
      }
      this.handleQuery()
    },
    goRouter(url, query) {
      this.$router.push({ path: url, query: query })
    },
    /** 查询参数列表 */
    getList () {
      this.onSelectChange([], [])
      this.loading = true
      // if (this.dateRange !== null && this.dateRange !== '' && this.dateRange.length === 2) {
      //   this.queryParam.createBeginTime = this.dateRange[0] + ' 00:00:00'
      //   this.queryParam.createEndTime = this.dateRange[1] + ' 23:59:59'
      // }
      devicePage(this.queryParam).then(response => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        }
      )
    },
    goTotal(id) {
      this.$router.push({ path: 'dataStatisticsTatal', query: { dataInfoId: id } })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.dateRange = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        typeName: 'LoRa水表',
        companyName: null,
        trade: null,
        stockCode: null,
        listingLocation: null
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 批量开关阀控制 */
    handleValve (row, status) {
      console.log(row, status)
      var that = this
      this.$confirm({
        title: '提示',
        content: `确认${status === 1 ? '开' : '关'}阀?`,
        onOk () {
          const list = []
          row.map(item => {
            list.push({
              id: item,
              status
            })
          })
          return deviceEditValve(list)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '操作成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const configIds = row.id ? [row.id] : this.ids
      this.$confirm({
        title: '提示',
        content: '确认删除所选中数据?',
        onOk () {
          return deviceDel(configIds)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('wisdom/wisdom-device/export', {
            ...that.queryParam
          }, `设备_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
<style scoped>
.spanBox{
  display: flex;
  flex-direction: column;
}
</style>
